import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Link } from "gatsby"
import shortid from  "shortid";

import { gsap } from "gsap";
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";


const HomeOurWorkSection = (props) => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const videos = document.querySelectorAll(".myVideo");
        videos.forEach(video =>{
            video.addEventListener("mouseenter", function () {
                video.play();
            });
            
            video.addEventListener("mouseleave", function () {
                video.pause();
                video.currentTime = 0;
            });
        })

    /*
       Work Thumbnail Setcion Interactions Begin
    */
        const workSectionTitle = document.querySelector(".work-section .section-title");
        const workSectionDescription = document.querySelector(".work-section .section-description");


        const workThumbnailLink = document.querySelectorAll(".work-section .portfolio-items .item .view-case-study");
        const workThumbnail = document.querySelectorAll(".work-section .portfolio-items .item .thumbnail");
        const workTitle = document.querySelectorAll(".work-section .portfolio-items .item .item-title .link-and-tag a");
        const workDescription = document.querySelectorAll(".work-section .portfolio-items .item .item-title .small");
        const workCategory = document.querySelectorAll(".work-section .portfolio-items .item .item-title .extra-small");
        
        workThumbnail[0].classList.add("xaqt-thumbnail-bg");
        workThumbnail[1].classList.add("tiltit-thumbnail-bg");
        workThumbnail[2].classList.add("cookingenie-thumbnail-bg");
        workThumbnail[3].classList.add("unikube-thumbnail-bg");
        workThumbnail[4].classList.add("evocalize-thumbnail-bg");
        workThumbnail[5].classList.add("eletive-thumbnail-bg");

        workThumbnailLink[0].classList.add("xaqt");
        workThumbnailLink[1].classList.add("tiltit");
        workThumbnailLink[2].classList.add("cookingenie");
        workThumbnailLink[3].classList.add("unikube");
        workThumbnailLink[4].classList.add("evocalize");
        workThumbnailLink[5].classList.add("eletive");

        //section title and description apearence
        gsap.set(workSectionTitle, { autoAlpha: 1 });
        
        const splitSectionTitle = new SplitType(workSectionTitle, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlSectionTitle = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section",
            start: "0 100%",
          }
        });
        tlSectionTitle.from(splitSectionTitle.words,{
          duration: .8,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workSectionDescription, { autoAlpha: 1 });
        
        const splitSectionDescription = new SplitType(workSectionDescription, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlSectionDescription = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section",
            start: "0 100%",
          }
        });
        tlSectionDescription.from(splitSectionDescription.words,{
          duration: .8,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          stagger: {
            amount: 0.1
          }
        });

        // xaqt
        const xaqtThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .xaqt",
            }
          })
          xaqtThumbnail.to(".work-section .portfolio-items .item .xaqt-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const xaqtThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .xaqt",
            }
          })
          xaqtThumbnailImage.to(".work-section .portfolio-items .item .xaqt-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })
  
          //tiltit
          const tiltitThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .tiltit",
            }
          })
          tiltitThumbnail.to(".work-section .portfolio-items .item .tiltit-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const tiltitThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .tiltit",
            }
          })
          tiltitThumbnailImage.to(".work-section .portfolio-items .item .tiltit-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })
  
          //cookingenie
          const cookingenieThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .cookingenie",
            }
          })
          cookingenieThumbnail.to(".work-section .portfolio-items .item .cookingenie-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const cookingenieThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .cookingenie",
            }
          })
          cookingenieThumbnailImage.to(".work-section .portfolio-items .item .cookingenie-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })
  
          //unikube
          const unikubeThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .unikube"
            }
          })
          unikubeThumbnail.to(".work-section .portfolio-items .item .unikube-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const unikubeThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .unikube"
            }
          })
          unikubeThumbnailImage.to(".work-section .portfolio-items .item .unikube-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })
  
          //evocalize
          const evocalizeThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .evocalize"
            }
          })
          evocalizeThumbnail.to(".work-section .portfolio-items .item .evocalize-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const evocalizeThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .evocalize"
            }
          })
          evocalizeThumbnailImage.to(".work-section .portfolio-items .item .evocalize-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })
  
          //eletive
          const eletiveThumbnail = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .eletive"
            }
          })
          eletiveThumbnail.to(".work-section .portfolio-items .item .eletive-thumbnail-bg", .8, {
            height: "auto",
            ease: "Power4.easeInOut",
          })
          const eletiveThumbnailImage = gsap.timeline({
            scrollTrigger: {
              trigger: ".work-section .portfolio-items .item .eletive"
            }
          })
          eletiveThumbnailImage.to(".work-section .portfolio-items .item .eletive-thumbnail-bg img", .8, {
            y: 0,
            ease: "Power4.easeInOut",
          })

        //xaqt work title description and project category
        // gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitle = new SplitType(workTitle[0], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitle = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .xaqt-thumbnail-bg",
          }
        });
        tlWorkTitle.from(splitWorkTitle.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescription = new SplitType(workDescription[0], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescription = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .xaqt-thumbnail-bg",
          }
        });
        tlWorkDescription.from(splitWorkDescription.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategory = new SplitType(workCategory[0], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategory = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .xaqt-thumbnail-bg",
          }
        });
        tlWorkCategory.from(splitWorkCategory.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        // // //tiltit work title description and project category
        // gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitleTiltit = new SplitType(workTitle[1], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitleTiltit = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .tiltit-thumbnail-bg",
          }
        });
        tlWorkTitleTiltit.from(splitWorkTitleTiltit.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescriptionTiltit = new SplitType(workDescription[1], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescriptionTiltit = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .tiltit-thumbnail-bg",
          }
        });
        tlWorkDescriptionTiltit.from(splitWorkDescriptionTiltit.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategoryTiltit = new SplitType(workCategory[1], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategoryTiltit = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .tiltit-thumbnail-bg",
          }
        });
        tlWorkCategoryTiltit.from(splitWorkCategoryTiltit.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        //cookingenie work title description and project category
        gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitleCookingenie = new SplitType(workTitle[2], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitleCookingenie = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .cookingenie-thumbnail-bg",
          }
        });
        tlWorkTitleCookingenie.from(splitWorkTitleCookingenie.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescriptionCookingenie = new SplitType(workDescription[2], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescriptionCookingenie = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .cookingenie-thumbnail-bg",
          }
        });
        tlWorkDescriptionCookingenie.from(splitWorkDescriptionCookingenie.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategoryCookingenie = new SplitType(workCategory[2], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategoryCookingenie = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .cookingenie-thumbnail-bg",
          }
        });
        tlWorkCategoryCookingenie.from(splitWorkCategoryCookingenie.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        // //unikube work title description and project category
        gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitleUnikube = new SplitType(workTitle[3], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitleUnikube = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .unikube-thumbnail-bg",
          }
        });
        tlWorkTitleUnikube.from(splitWorkTitleUnikube.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescriptionUnikube = new SplitType(workDescription[3], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescriptionUnikube = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .unikube-thumbnail-bg",
          }
        });
        tlWorkDescriptionUnikube.from(splitWorkDescriptionUnikube.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategoryUnikube = new SplitType(workCategory[3], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategoryUnikube = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .unikube-thumbnail-bg",
          }
        });
        tlWorkCategoryUnikube.from(splitWorkCategoryUnikube.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        // //evocalize work title description and project category
        gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitleEvocalize = new SplitType(workTitle[4], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitleEvocalize = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .evocalize-thumbnail-bg",
          }
        });
        tlWorkTitleEvocalize.from(splitWorkTitleEvocalize.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescriptionEvocalize = new SplitType(workDescription[4], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescriptionEvocalize = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .evocalize-thumbnail-bg",
          }
        });
        tlWorkDescriptionEvocalize.from(splitWorkDescriptionEvocalize.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategoryEvocalize = new SplitType(workCategory[4], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategoryEvocalize = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .evocalize-thumbnail-bg",
          }
        });
        tlWorkCategoryEvocalize.from(splitWorkCategoryEvocalize.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        //Eletive work title description and project category
        gsap.set(workTitle, { autoAlpha: 1 });
        
        const splitWorkTitleEletive = new SplitType(workTitle[5], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkTitleEletive = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .eletive-thumbnail-bg",
          }
        });
        tlWorkTitleEletive.from(splitWorkTitleEletive.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(workDescription, { autoAlpha: 1 });
        
        const splitWorkDescriptionEletive = new SplitType(workDescription[5], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkDescriptionEletive = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .eletive-thumbnail-bg",
          }
        });
        tlWorkDescriptionEletive.from(splitWorkDescriptionEletive.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .3,
        });

        gsap.set(workCategory, { autoAlpha: 1 });
        
        const splitWorkCategoryEletive = new SplitType(workCategory[5], {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlWorkCategoryEletive = gsap.timeline({
          scrollTrigger:{
            trigger: ".work-section .portfolio-items .item .eletive-thumbnail-bg",
          }
        });
        tlWorkCategoryEletive.from(splitWorkCategoryEletive.words,{
          duration: 1,
          yPercent: 150,
          ease: "Power4.easeInOut",
          delay: .4,
        });

        const tlTag = gsap.timeline({
          scrollTrigger: {
            trigger: ".work-section .portfolio-items .item .eletive-thumbnail-bg",
          }
        })
        tlTag.to(".work-section .portfolio-items .item .link-and-tag .tag", 1, {
          y: 0,
          ease: "Power4.easeInOut",
          delay: .2,
          stagger: {
            amount: 0.1
          }
        })
    },[])
    
    // useEffect(()=>{
    //     const sectionTitle = document.querySelector(".element-reveal");
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             if(entry.isIntersecting){
    //                 let tl = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
    //                 tl.from(".work-heading", 1.3, {
    //                   y: 130,
    //                   ease: "Power4.easeInOut",
    //                 //   delay: 5.5,
    //                 //   skewY: 5,
    //                   stagger: {
    //                     amount: 0.1
    //                   }
    //                 })
    //             }
    //         })
    //     })
    //     observer.observe(sectionTitle);
    // },[])

    return (
        <section className="work-section" >
            <div className="container">
                <div className="row element-reveal" >
                    <div className="col-lg-20">
                        <h2 className="h2 section-title normal-spacing work-heading">{props.mainTitle}</h2>
                        <p className="section-description">{props.mainDescription}</p>
                    </div>
                </div>
                <div className="portfolio-items">
                    <div className="row">
                        {props.list?.map((object, i) => {
                            return (
                                <>
                                <div className={((i+1)%3 === 1) ? 'col-lg-17' : 'col-lg-11'}  key={shortid.generate()}>
                                    
                                        <div className="item">
                                            {/* <ScaleUp delay={200} duration={500}> */}
                                                <Link to={object.url} target="_blank" className='view-case-study' data-hover="View CaseStudy">
                                                    <div className="thumbnail">
                                                        <img
                                                            src={object.thumbnail1x?.sourceUrl}
                                                            srcSet={object.thumbnail2x?.sourceUrl + " 2x, " + object.thumbnail1x?.sourceUrl + " 1x"}
                                                            width={object.thumbnail1x?.width}
                                                            alt={object.thumbnail1x?.altText}
                                                        />
                                                        <video
                                                        src={object.thumbnailAnimation} 
                                                        muted 
                                                        loop
                                                        autoPlay
                                                        className="myVideo"
                                                        style={{width: "100%"}}
                                                        ></video>
                                                    </div>
                                                </Link>
                                                <p className="extra-large item-title">
                                                    <div className="link-and-tag">
                                                    {/* link */}
                                                    {(object.url === null) ? (
                                                        <Link className="project-link" to='/'>{parse(object.title)}</Link> 
                                                    ) : (
                                                        <Link to={object.url} target="_blank">{parse(object.title)}</Link>
                                                    )}
                                                    {/* Tag */}
                                                    {(object.tag === 'cm') ? (
                                                      <span className="tag primary-tag">Upcoming</span>
                                                    ) : (
                                                        (object.tag === 'ip') ? (
                                                            <span className="tag green-tag">In Progress</span>
                                                        ) : (
                                                            ''
                                                        )   
                                                    )}
                                                    </div>
                                                    <p className="small">{parse(object.mainDescription)}</p>
                                                    <p className="extra-small">{parse(object.description)}</p>
                                                </p>
                                            {/* </ScaleUp> */}
                                        </div>
                                </div>
                                <div className={((i+2)%3 === 1) ? '' : 'col-lg-2'}></div>
                                </>
                            )
                        })}
                    </div>
                    {/* <SlideUp duration={400} delay={500}> */}
                        <div className="btn-block" >
                            <Link to="/work" className="btn outline-btn" >
                                <span className="btn-text">View Work</span>
                                <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
                            </Link>
                        </div>
                    {/* </SlideUp> */}
                </div>
            </div>
        </section>
    )
}

export default HomeOurWorkSection